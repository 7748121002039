<template>
    <div class="my-12" v-cloak>
        <div class="text-center">
            <h2 class="text-3xl font-light lg:text-5xl text-darkGray">
                Explore what we offer...
            </h2>
        </div>

        <div class="my-12">
            <div class="justify-around hidden w-10/12 mx-auto my-0 md:flex">
                <div
                    class="cursor-pointer px-6 text-2xl border-2 font-bold rounded-full text-rivaudo-green border-rivaudo-green pb-1.5 pt-2 active:bg-rivaudo-green active:text-white"
                    :class="{
                        'bg-rivaudo-green text-white':
                            selectedService === 'ecommerce',
                    }"
                    @click="selectedService = 'ecommerce'"
                >
                    E-commerce
                </div>
                <div
                    class="cursor-pointer px-6 text-2xl border-2 font-bold rounded-full text-rivaudo-green border-rivaudo-green pb-1.5 pt-2 active:bg-rivaudo-green active:text-white"
                    :class="{
                        'bg-rivaudo-green text-white':
                            selectedService === 'banking',
                    }"
                    @click="selectedService = 'banking'"
                >
                    Banking
                </div>
                <div
                    class="cursor-pointer px-6 text-2xl border-2 font-bold rounded-full text-rivaudo-green border-rivaudo-green pb-1.5 pt-2 active:bg-rivaudo-green active:text-white"
                    :class="{
                        'bg-rivaudo-green text-white':
                            selectedService === 'cybersecurity',
                    }"
                    @click="selectedService = 'cybersecurity'"
                >
                    Cybersecurity
                </div>
            </div>
            <select
                v-model="selectedService"
                @change="handleChange"
                class="block w-10/12 px-4 py-2 mx-auto my-0 text-2xl border md:hidden text-darkGray border-darkGray rounded-2xl"
            >
                <option class="text-darkGray" value="ecommerce">
                    E-commerce
                </option>
                <option class="text-darkGray" value="banking">Banking</option>
                <option class="text-darkGray" value="cybersecurity">
                    Cybersecurity
                </option>
            </select>
        </div>
        <hr class="w-10/12 mx-auto mb-16 border-secondary-500" />
        <div
            class="container flex flex-col items-center w-10/12 mx-auto md:flex-row"
        >
            <div class="md:w-1/2">
                <h3
                    class="text-4xl font-light sm:text-5xl xl:text-7xl lg:text-5xl text-darkGray"
                    v-html="selectedServiceContent.title"
                ></h3>
                <p class="my-8 lg:text-lg">
                    {{ selectedServiceContent.paragraph }}
                </p>
                <router-link class="text-white rivaudo-green">
                    Learn more <img src="@/assets/icons/white-arrow-right.svg"
                /></router-link>
            </div>
            <div class="md:w-1/2">
                <img :src="selectedServiceContent.image" />
            </div>
        </div>
    </div>
</template>

<script>
import ecommerceImage from "@/assets/macbook-air-15in.jpg";
import bankingImage from "@/assets/hard-cover-notebook.jpg";
import cybersecurityImage from "@/assets/secret-folder.jpg";

export default {
    data() {
        return {
            selectedService: "ecommerce",
            serviceContent: [
                {
                    title: `Less Software,
                            <br />
                            more
                            <span class='font-bold text-rivaudo-green'>
                                growth.
                            </span>`,
                    paragraph:
                        "Streamline your operations with our e-commerce solutions designed to simplify and accelerate your business growth. We believe that the right software should do more with less, helping you focus on what truly matters—scaling your business. Our intuitive tools eliminate unnecessary complexity, so you can manage your sales, inventory, and customer interactions effortlessly. Experience a smarter approach to e-commerce that empowers you to grow faster, with fewer barriers and greater efficiency.",
                    image: ecommerceImage,
                },
                {
                    title: `Bookkeeping,
                            <br />
                            made
                            <span class="font-bold text-rivaudo-green"> easy. </span>`,
                    paragraph:
                        "Manage your finances effortlessly with our banking software, designed to take the stress out of bookkeeping. Whether you're tracking expenses, managing accounts, or preparing financial reports, our intuitive tools make it simple and straightforward. With real-time insights and automated processes, you can stay on top of your finances without the hassle. Focus on growing your business, while we handle the numbers—making bookkeeping easier than ever before.",
                    image: bankingImage,
                },
                {
                    title: `Data security,
                            <br />
                            at
                            <span class="font-bold text-rivaudo-green">
                                all levels.
                            </span>`,
                    paragraph:
                        "Protect your business from threats with our comprehensive cybersecurity solutions, designed to secure your data at every level. From safeguarding sensitive information to monitoring potential risks, our advanced tools provide robust protection across all your digital assets. Whether you're a small business or a large enterprise, our solutions adapt to your needs, ensuring that your data remains secure and your operations uninterrupted.",
                    image: cybersecurityImage,
                },
            ],
        };
    },
    methods: {
        handleChange(e) {
            this.selectedService = e.target.value;
        },
    },
    computed: {
        selectedServiceContent() {
            switch (this.selectedService) {
                case "ecommerce":
                    return this.serviceContent[0];
                case "banking":
                    return this.serviceContent[1];
                case "cybersecurity":
                    return this.serviceContent[2];
                default:
                    return this.serviceContent[0];
            }
        },
    },
    mounted() {},
};
</script>

<style>
select {
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>')
        no-repeat right 0.75rem center;
    background-size: 2.5rem;
    outline: none;
    -webkit-appearance: none;
}

option {
    width: 100%;
}
</style>
