<template>
    <div>
        <div class="flex flex-col items-center my-40">
            <div class="px-4">
                <img src="../assets/successful-message-sent.svg" />
            </div>
            <div class="my-20 text-center">
                <h2
                    class="mb-8 text-4xl font-bold lg:text-5xl text-rivaudo-green"
                >
                    Message Sent!
                </h2>
                <p class="text-lg font-light sm:text-2xl text-darkGray">
                    Your message has successfully launched.<br />We’ll get back
                    to you shortly.
                </p>
            </div>
            <div>
                <Button
                    text="Return to base"
                    link="home"
                    class="w-5/6 mx-auto text-rivaudo-green bg-rivaudo-green"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../components/Button.vue";

export default {
    components: {
        Button,
    },
};
</script>

<style></style>
