<template>
    <div id="faq">
        <div class="pt-12 pb-14">
            <h2 class="text-5xl font-bold text-center text-darkGray">FAQ</h2>
        </div>
        <div class="container mx-auto">
            <FAQ
                question="What is Rivaudo?"
                answer="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo sunt nulla, minus id cumque dolore quam architecto dolor, fugiat numquam alias dicta illum veniam magnam!"
            />
            <FAQ
                question="lorem fdsaf dfalh asfd adf?"
                answer="fasdf klja dfahl fakl asf fasl afk ladfal kadfh"
            />
            <FAQ
                question="lorem fdsaf dfalh asfd adf?"
                answer="fasdf klja dfahl fakl asf fasl afk ladfal kadfh"
            />
        </div>
    </div>
</template>

<script>
import FAQ from "../FAQ.vue";

export default {
    components: {
        FAQ,
    },
};
</script>

<style></style>
