<template>
    <div class="flex flex-col items-center justify-around lg:flex-row">
        <div class="flex flex-col items-center text-center lg:w-1/2">
            <p class="font-bold uppercase text-darkGray">Secure & Custom</p>
            <hr class="block w-32 mt-3 border-none bg-rivaudo-green h-0.5" />
            <h1
                class="mt-10 text-5xl text-darkGray md:text-6xl lg:text-7xl 2xl:text-8xl mb-7"
            >
                <span class="font-light">Software</span>
                <br />
                <span class="font-semibold text-rivaudo-green">
                    built for you.
                </span>
            </h1>
            <h2
                class="text-sm font-normal sm:text-xl text-darkGray lg:text-lg md:text-2xl"
            >
                Empower your business with modular AI-integrated
                <br class="hidden lg:block" />
                enterprise software, engineered to perfection.
            </h2>
            <div
                class="flex flex-col justify-center gap-8 mt-8 lg:w-fit lg:flex-row"
            >
                <Button
                    text="Get started"
                    link="about"
                    class="text-rivaudo-green xl:text-lg bg-rivaudo-green"
                />
                <Button
                    text="Request quote"
                    link="about"
                    btnType="secondary"
                    class="text-darkGray border-darkGray xl:text-lg"
                />
            </div>
        </div>
        <div class="p-1 pt-10 lg:px-6 lg:w-1/2">
            <img
                loading="eager"
                width="940"
                height="824"
                src="@/assets/banners/main.svg"
                alt="Mac computer with Rivaudo logo and UI components floating around, showcasing software design and interface elements."
            />
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button.vue";
export default {
    components: {
        Button,
    },
};
</script>

<style></style>
