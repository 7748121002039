<template>
  <about-banner></about-banner>
  <about-secure></about-secure>
  <about-scalable></about-scalable>
  <about-available></about-available>
</template>

<script>
import AboutBanner from '@/components/about/AboutBanner.vue';
import AboutSecure from '@/components/about/AboutSecure.vue';
import AboutScalable from '@/components/about/AboutScalable.vue';
import AboutAvailable from '@/components/about/AboutAvailable.vue';

export default {
  components: {
    AboutBanner,
    AboutSecure,
    AboutScalable,
    AboutAvailable
  }
}
</script>

<style>

</style>