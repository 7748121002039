<template>
    <div
        @click.capture="toggleFAQ"
        class="w-10/12 px-4 py-5 mx-auto my-12 cursor-pointer rounded-2xl"
        :class="{ 'outline-rivaudo-green outline': answerOpen }"
        style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
    >
        <div class="flex items-center justify-between">
            <h3 class="text-lg font-bold text-darkGray">{{ question }}</h3>
            <div
                class="flex items-center justify-center w-5 h-5 pl-[0.1rem] transition-all rounded-full"
                :class="{ ' rotate-90': answerOpen }"
                style="box-shadow: 0px 0.5px 5px 1px rgba(0, 0, 0, 0.1)"
            >
                <img class="h-1/2" src="@/assets/icons/green-chevron.svg" />
            </div>
        </div>
        <div class="my-4" v-if="answerOpen">
            <p class="text-darkGray">
                {{ answer }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        question: {
            type: String,
            required: true,
        },
        answer: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            answerOpen: false,
        };
    },
    methods: {
        toggleFAQ() {
            this.answerOpen = !this.answerOpen;
        },
    },
};
</script>

<style></style>
