<template>
    <div class="py-10 my-16 bg-gray-100" id="news">
        <div class="container mx-auto">
            <div class="pt-12 pb-14">
                <h2 class="text-5xl font-bold text-center text-darkGray">
                    What's new?
                </h2>
            </div>
            <div
                class="flex flex-col w-10/12 gap-12 mx-auto my-0 lg:flex-row lg:w-full"
            >
                <div
                    class="overflow-hidden rounded-3xl"
                    style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
                >
                    <div>
                        <img
                            src="@/assets/blog-dummy-image.jpg"
                            class="w-full"
                        />
                    </div>
                    <div class="px-4 py-6 bg-white">
                        <h3 class="text-xl font-bold text-darkGray">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                        </h3>
                        <p
                            class="mt-4 mb-10 text-base font-medium text-darkGray"
                        >
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Dolorem similique explicabo deleniti
                            consectetur dicta, placeat accusamus, voluptate
                            mollitia facilis tempore quia harum, accusantium at
                            maxime.
                        </p>
                        <div>
                            <router-link
                                class="flex gap-3 border-darkGray border w-fit pt-2 pb-1.5 px-4 rounded-full hover:opacity-40"
                                >Read More
                                <img src="@/assets/icons/arrow-right.svg"
                            /></router-link>
                        </div>
                    </div>
                </div>
                <div
                    class="overflow-hidden rounded-3xl"
                    style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
                >
                    <div>
                        <img
                            src="@/assets/blog-dummy-image.jpg"
                            class="w-full"
                        />
                    </div>
                    <div class="px-4 py-6 bg-white">
                        <h3 class="text-xl font-bold text-darkGray">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                        </h3>
                        <p
                            class="mt-4 mb-10 text-base font-medium text-darkGray"
                        >
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Dolorem similique explicabo deleniti
                            consectetur dicta, placeat accusamus, voluptate
                            mollitia facilis tempore quia harum, accusantium at
                            maxime.
                        </p>
                        <div>
                            <router-link
                                class="flex gap-3 border-darkGray border w-fit pt-2 pb-1.5 px-4 rounded-full hover:opacity-40"
                                >Read More
                                <img src="@/assets/icons/arrow-right.svg"
                            /></router-link>
                        </div>
                    </div>
                </div>
                <div
                    class="overflow-hidden rounded-3xl"
                    style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
                >
                    <div>
                        <img
                            src="@/assets/blog-dummy-image.jpg"
                            class="w-full"
                        />
                    </div>
                    <div class="px-4 py-6 bg-white">
                        <h3 class="text-xl font-bold text-darkGray">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                        </h3>
                        <p
                            class="mt-4 mb-10 text-base font-medium text-darkGray"
                        >
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Dolorem similique explicabo deleniti
                            consectetur dicta, placeat accusamus, voluptate
                            mollitia facilis tempore quia harum, accusantium at
                            maxime.
                        </p>
                        <div>
                            <router-link
                                class="flex gap-3 border-darkGray border w-fit pt-2 pb-1.5 px-4 rounded-full hover:opacity-40"
                                >Read More
                                <img src="@/assets/icons/arrow-right.svg"
                            /></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center my-12">
                <router-link
                    class="bg-rivaudo-green hover:bg-rivaudo-green-600 text-white font-bold px-8 pt-2 pb-1.5 uppercase rounded-full"
                >
                    View More
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
