<template>
    <div class="my-40 lg:my-0 lg:pb-60 lg:pt-20">
        <div class="mx-auto my-0 w-fit">
            <img
                class="w-2/3 mx-auto my-4 lg:w-1/2"
                src="../assets/404-image.jpg"
            />
            <div class="px-4 text-center">
                <h2
                    class="text-2xl font-bold sm:text-4xl md:text-5xl lg:text-6xl text-rivaudo-green"
                >
                    Houston, we have a problem!
                </h2>
                <p class="my-12 text-xl text-darkGray">
                    Looks like this page got lost in space, let’s get you back
                    to base.
                </p>
                <Button
                    text="Return to base"
                    link="home"
                    class="bg-rivaudo-green"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
    components: {
        Button,
    },
};
</script>

<style></style>
