<template>
    <div>
        <div>
            <h2
                class="text-3xl font-medium text-center text-darkGray lg:text-5xl xl:text-6xl md:text-4xl lg:mt-36"
            >
                Get in touch with us.
                <br />
                We're here to assist you.
            </h2>
        </div>
        <div class="w-10/12 mx-auto my-20">
            <form>
                <div class="gap-10 my-12 items-base lg:flex lg:justify-between">
                    <input
                        type="text"
                        class="w-full pb-1 my-4 text-2xl transition-all border-b-2 rounded-none outline-none lg:my-0 lg:w-2/5 focus:border-rivaudo-green-500"
                        placeholder="Your Name"
                    />
                    <input
                        type="email"
                        class="w-full pb-1 mt-12 mb-6 text-2xl transition-all border-b-2 rounded-none outline-none lg:my-0 lg:w-2/5 focus:border-rivaudo-green-500"
                        placeholder="Email Address"
                    />
                    <input
                        type="phone"
                        class="w-full pb-1 mt-4 mb-16 text-2xl transition-all border-b-2 rounded-none outline-none lg:my-0 lg:w-1/3 focus:border-rivaudo-green-500"
                        placeholder="Phone Number (optional)"
                    />
                </div>
                <label class="text-2xl text-gray-400">How can we help?</label>
                <textarea
                    rows="10"
                    class="w-full p-2 my-4 text-2xl transition-all border-2 outline-none rounded-xl focus:border-rivaudo-green-500"
                ></textarea>
                <input
                    @click="submitForm"
                    type="submit"
                    class="block w-10/12 px-10 pt-2 pb-1 mx-auto my-8 text-2xl uppercase border rounded-full cursor-pointer lg:w-fit lg:mx-0 text-rivaudo-green border-rivaudo-green"
                />
            </form>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        // Before routing to submit page (also check for errors), send data to email.
        submitForm() {
            this.$router.push({ path: "submit" });
        },
    },
};
</script>

<style></style>
