<template>
    <div class="pt-24 pb-40">
        <div
            class="bg-cover bg-center xs:bg-gradient-to-r from-rivaudo-green to-secondary-500 flex flex-col items-center lg:flex-row justify-around lg:h-[250px] mobile-bg"
        >
            <div
                style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
                class="relative w-10/12 p-6 bg-white bottom-16 lg:bottom-0 lg:w-1/4 h-fit rounded-xl"
            >
                <div class="mb-4">
                    <img
                        class="md:w-14"
                        src="../../assets/icons/globe-solid.svg"
                    />
                </div>
                <div>
                    <h2 class="text-2xl font-bold">E-commerce</h2>
                </div>
                <hr
                    class="block w-1/4 my-4 border-none h-0.5 bg-rivaudo-green"
                />
                <div>
                    <p class="text-sm text-darkGray">
                        Empower your digital commerce strategy with our advanced
                        ecommerce solutions. We offer intuitive UX/UI design,
                        PCI DSS-compliant payment processing, and personalized
                        customer journey optimization to drive online sales and
                        enhance customer satisfaction.
                    </p>
                </div>
                <div class="mt-6">
                    <router-link
                        class="flex items-center gap-4 px-3 py-1 font-extrabold uppercase border border-darkGray text-darkGray rounded-3xl w-fit hover:opacity-40"
                        :to="{ name: 'about' }"
                        ><span class="mt-0.5">Learn more</span
                        ><img src="../../assets/icons/chevron-right.svg"
                    /></router-link>
                </div>
            </div>
            <div
                style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
                class="w-10/12 p-6 bg-white h-fit rounded-xl lg:w-1/4"
            >
                <div class="mb-4">
                    <img
                        class="md:w-14"
                        src="../../assets/icons/banking-solid.svg"
                    />
                </div>
                <div>
                    <h2 class="text-2xl font-bold">Banking</h2>
                </div>
                <hr
                    class="block w-1/4 my-4 border-none h-0.5 bg-rivaudo-green"
                />
                <div>
                    <p class="text-sm text-darkGray">
                        Enhance financial operations with our leading-edge
                        banking products featuring real-time transaction
                        processing, integrated fraud prevention, and blockchain
                        technology. We provide robust regulatory compliance with
                        KYC, AML, and GDPR frameworks.
                    </p>
                </div>
                <div class="mt-6">
                    <router-link
                        class="flex items-center gap-4 px-3 py-1 font-extrabold uppercase border border-darkGray text-darkGray rounded-3xl w-fit hover:opacity-40"
                        :to="{ name: 'about' }"
                        ><span class="mt-0.5">Learn more</span
                        ><img src="../../assets/icons/chevron-right.svg"
                    /></router-link>
                </div>
            </div>
            <div
                style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
                class="relative w-10/12 p-6 bg-white top-16 lg:top-0 h-fit lg:w-1/4 rounded-xl"
            >
                <div class="mb-4">
                    <img
                        class="md:w-14"
                        src="../../assets/icons/shield-solid.svg"
                    />
                </div>
                <div>
                    <h2 class="text-2xl font-bold">Cybersecurity</h2>
                </div>
                <hr
                    class="block w-1/4 my-4 border-none h-0.5 bg-rivaudo-green"
                />
                <div>
                    <p class="text-sm text-darkGray">
                        Safeguard your business with our state-of-the-art
                        security platforms. Offering AI-driven threat detection,
                        24/7 SOC monitoring, and rapid incident response. Our
                        services ensure compliance with regulations, mitigate
                        risks, and protect your critical assets from cyber
                        threats.
                    </p>
                </div>
                <div class="mt-6">
                    <router-link
                        class="flex items-center gap-4 px-3 py-1 font-extrabold uppercase border border-darkGray text-darkGray rounded-3xl w-fit hover:opacity-40"
                        :to="{ name: 'about' }"
                        ><span class="mt-0.5">Learn more</span
                        ><img src="../../assets/icons/chevron-right.svg"
                    /></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../Button.vue";

export default {
    components: {
        Button,
    },
};
</script>

<style>
.mobile-bg {
    @media only screen and (max-width: 480px) {
        background-image: url("../../assets/background.svg");
    }
}
</style>
