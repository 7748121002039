<template>
    <div>
        <div
            class="flex flex-col items-center gap-4 px-4 pt-16 mx-auto mb-16 text-center md:my-36 sm:pt-32"
        >
            <div class="flex items-baseline justify-center w-full gap-3">
                <img
                    src="../assets/rivaudo.svg"
                    alt="Rivaudo Company Logo - Software Solutions for Business Operations"
                    class="w-3/5 max-w-md sm:w-1/2"
                />
                <p
                    class="relative text-2xl font-light md:text-5xl sm:text-5xl bottom-1 text-rivaudo-green"
                >
                    Tech Talk
                </p>
            </div>
            <p class="font-light md:text-xl xl:w-1/2 lg:w-4/5">
                Dive into our extensive library of resources, including blogs,
                news updates, and FAQs, designed to keep you ahead in the world
                of software.
            </p>
        </div>
        <div>
            <div
                class="hidden w-10/12 grid-cols-3 gap-12 mx-auto my-0 md:grid justify-items-center"
            >
                <div
                    v-for="(categoryTitle, indx) in categories"
                    :key="indx"
                    class="capitalize-first cursor-pointer px-6 text-2xl border-2 font-bold rounded-full w-fit text-rivaudo-green border-rivaudo-green pb-1.5 pt-2 active:bg-rivaudo-green active:text-white"
                    :class="{
                        'bg-rivaudo-green text-white':
                            category === categoryTitle,
                    }"
                    @click="category = categoryTitle"
                >
                    {{ categoryTitle }}
                </div>
            </div>
            <select
                @change="onCategoryChange"
                v-model="category"
                class="block w-10/12 px-4 py-2 mx-auto my-0 text-2xl border md:hidden text-darkGray border-darkGray rounded-2xl"
            >
                <option
                    v-for="(categoryTitle, indx) in categories"
                    :key="indx"
                    class="capitalize-first text-darkGray"
                    :value="categoryTitle"
                >
                    <div class="capitalize-first">{{ categoryTitle }}</div>
                </option>
            </select>
        </div>
        <hr class="w-10/12 mx-auto my-10" />
        <div class="justify-between w-10/12 pb-10 mx-auto lg:flex">
            <ul class="text-left">
                <li
                    class="pl-2 my-8 text-lg border-l-2 lg:text-2xl hover:text-rivaudo-green border-l-secondary-500"
                    v-for="(blog, indx) in categoryList"
                    :key="indx"
                >
                    <router-link
                        :to="{ name: 'blog', params: { slug: blog.slug } }"
                    >
                        {{ blog.title }}
                    </router-link>
                </li>
            </ul>
            <div>
                <img src="../assets/paper-coffee-cup.jpg" />
            </div>
        </div>
    </div>
</template>

<script>
import blogs from "@/blogs/blogsList.json";

export default {
    data() {
        return {
            blogs: blogs,
            category: "E-commerce",
            categories: [],
        };
    },
    beforeMount() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            for (let category in blogs) {
                this.categories.push(category);
            }
        },
    },
    computed: {
        categoryList() {
            return blogs[this.category];
        },
    },
};
</script>

<style></style>
