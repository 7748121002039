<template>
  <services-banner></services-banner>
  <services-explore></services-explore>
</template>

<script>
import ServicesBanner from '@/components/services/ServicesBanner.vue';
import ServicesExplore from '@/components/services/ServicesExplore.vue';

export default {
  components: {
    ServicesBanner,
    ServicesExplore
  }
}
</script>

<style>

</style>