<template>
    <div class="w-full bg-gray-100 md:pt-20 h-fit">
        <div class="flex flex-col justify-between lg:flex-row gap-y-12">
            <div class="w-10/12 pb-4 mx-auto my-0 lg:px-0 lg:ml-32">
                <div>
                    <h3
                        class="mt-10 text-2xl lg:text-5xl sm:text-4xl text-darkGray mb-7"
                    >
                        <span class="font-light"> The value of software </span>
                        <br />
                        <span class="font-bold text-rivaudo-green">
                            lies within its availability.
                        </span>
                    </h3>
                </div>
                <div class="w-full my-8 md:w-2/3">
                    <p class="font-semibold text-darkGray">
                        Ensuring constant access to powerful software solutions
                        is key to unlocking business potential, sustaining
                        growth, and driving innovation.
                    </p>
                </div>
                <div
                    class="flex flex-col items-center gap-x-4 md:flex-row gap-y-8"
                >
                    <router-link
                        class="md:px-4 px-8 pt-3 pb-2.5 w-fit font-bold text-white uppercase rounded-full bg-black hover:bg-transparent hover:outline-black hover:outline-1 hover:outline hover:text-black"
                        :to="{ name: 'about' }"
                        >View Changelog</router-link
                    >
                    <router-link
                        class="md:px-4 px-8 pt-3 pb-2.5 w-fit font-bold text-black border border-black uppercase rounded-full bg-transparent hover:bg-black hover:text-white"
                        :to="{ name: 'about' }"
                        >Request a quote</router-link
                    >
                </div>
            </div>
            <div class="flex items-end justify-end">
                <img src="../../assets/holding-phone.svg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
