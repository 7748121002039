<template>
    <div class="my-12">
        <div
            class="flex flex-col-reverse mb-12 md:justify-between md:flex-row md:items-center"
        >
            <div
                class="flex flex-col text-center md:text-left md:pl-16 gap-y-3"
            >
                <h1
                    class="text-4xl font-light xl:text-6xl 2xl:text-7xl md:text-4xl lg:text-5xl xs:text-5xl text-darkGray"
                >
                    Enterprise software
                    <br />
                    <span class="font-bold text-rivaudo-green"
                        >made better.</span
                    >
                </h1>
                <p
                    class="px-4 text-sm md:px-0 md:text-base xl:text-lg sm:text-lg 2xl:w-3/4"
                >
                    Specializing in software solutions for ecommerce, banking,
                    and cybersecurity business operations.
                </p>
            </div>
            <div class="flex justify-end">
                <img
                    class="lg:w-[40rem]"
                    width="640"
                    height="567"
                    src="@/assets/banners/wood-hand-holding-phone.webp"
                />
            </div>
        </div>
        <img
            class="w-full"
            src="@/assets/banner-bottom.png"
            alt="Green banner below header"
        />
        <div class="mt-8 text-center">
            <h2 class="text-4xl font-light lg:text-6xl text-darkGray">
                Software should be...
            </h2>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
