import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Services from "../views/Services.vue";
import Resources from "../views/Resources.vue";
import Contact from "../views/ContactPage.vue";
import NotFound from "../views/404.vue";
import AllBlogs from "../views/AllBlogs.vue";
import BlogPage from "@/views/BlogPage.vue";
import SubmitPage from "@/views/SubmitPage.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/services",
        name: "services",
        component: Services,
    },
    {
        path: "/resources",
        name: "resources",
        component: Resources,
    },
    {
        path: "/about",
        name: "about",
        component: About,
    },
    {
        path: "/blogs",
        name: "blogs",
        component: AllBlogs,
    },
    {
        path: "/blogs/:slug",
        name: "blog",
        component: BlogPage,
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact,
    },
    {
        path: "/submit",
        name: "submit",
        component: SubmitPage,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "notfound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

export default router;
