<template>
    <header class="fixed top-0 z-10 w-full bg-white shadow-lg">
        <nav
            class="flex items-center justify-between px-4 py-4 lg:items-end lg:px-20"
        >
            <router-link to="/" @click="closeMobileMenu">
                <img
                    src="@/assets/rivaudo.svg"
                    alt="Rivaudo logo - enterprise software solutions company"
                    title="Rivaudo"
                />
            </router-link>
            <ul class="hidden lg:flex">
                <li class="ml-6 primary">
                    <router-link
                        :to="{ name: 'home' }"
                        class="font-bold uppercase text-darkGray"
                        active-class="active"
                        exact
                        >Home</router-link
                    >
                </li>
                <li class="ml-6 primary">
                    <router-link
                        :to="{ name: 'services' }"
                        class="font-bold uppercase text-darkGray"
                        active-class="active"
                        exact
                        >Services</router-link
                    >
                </li>
                <li class="ml-6 primary">
                    <router-link
                        :to="{ name: 'resources' }"
                        class="font-bold uppercase text-darkGray"
                        active-class="active"
                        exact
                        >Resources</router-link
                    >
                </li>
                <li class="ml-6 primary">
                    <router-link
                        :to="{ name: 'about' }"
                        class="font-bold uppercase text-darkGray"
                        active-class="active"
                        exact
                        >About us</router-link
                    >
                </li>
                <li class="ml-6">
                    <router-link
                        class="bg-rivaudo-green text-white pt-2 pb-1.5 rounded-3xl uppercase px-4 font-bold hover:bg-rivaudo-green-700"
                        :to="{ name: 'contact' }"
                        >Contact</router-link
                    >
                </li>
            </ul>
            <div
                class="lg:hidden cursor-pointer flex flex-col gap-1.5 items-end"
                @click="mobileMenu"
            >
                <div
                    id="top"
                    class="border border-secondary-500 border-b-[0.20rem] w-8 transition-all"
                ></div>
                <div
                    id="middle"
                    class="border border-secondary-500 border-b-[0.20rem] w-6 transition-all"
                ></div>
                <div
                    id="bottom"
                    class="border border-secondary-500 border-b-[0.20rem] w-8 transition-all"
                ></div>
            </div>
        </nav>
        <div v-if="mobileMenuOpen" class="h-screen bg-white">
            <ul
                class="flex flex-col justify-center w-5/6 gap-16 mx-auto my-0 mt-10"
            >
                <li>
                    <router-link to="/" @click="mobileMenu()">
                        <div class="flex justify-between">
                            <h3
                                class="text-2xl font-light uppercase text-darkGray"
                            >
                                Home
                            </h3>
                            <img src="../assets/icons/chevron-right.svg" />
                        </div>
                        <div
                            class="mt-1 h-0.5 bg-gradient-to-r from-rivaudo-green to-secondary-500"
                        ></div>
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'services' }"
                        @click="mobileMenu()"
                    >
                        <div class="flex justify-between">
                            <h3
                                class="text-2xl font-light uppercase text-darkGray"
                            >
                                Services
                            </h3>
                            <img src="../assets/icons/chevron-right.svg" />
                        </div>
                        <div
                            class="mt-1 h-0.5 bg-gradient-to-r from-rivaudo-green to-secondary-500"
                        ></div>
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'resources' }"
                        @click="mobileMenu()"
                    >
                        <div class="flex justify-between">
                            <h3
                                class="text-2xl font-light uppercase text-darkGray"
                            >
                                Resources
                            </h3>
                            <img src="../assets/icons/chevron-right.svg" />
                        </div>
                        <div
                            class="mt-1 h-0.5 bg-gradient-to-r from-rivaudo-green to-secondary-500"
                        ></div>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'about' }" @click="mobileMenu()">
                        <div class="flex justify-between">
                            <h3
                                class="text-2xl font-light uppercase text-darkGray"
                            >
                                About us
                            </h3>
                            <img src="../assets/icons/chevron-right.svg" />
                        </div>
                        <div
                            class="mt-1 h-0.5 bg-gradient-to-r from-rivaudo-green to-secondary-500"
                        ></div>
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'contact' }"
                        @click="mobileMenu()"
                    >
                        <div class="flex justify-between">
                            <h3
                                class="text-2xl font-light uppercase text-darkGray"
                            >
                                Contact
                            </h3>
                            <img src="../assets/icons/chevron-right.svg" />
                        </div>
                        <div
                            class="mt-1 h-0.5 bg-gradient-to-r from-rivaudo-green to-secondary-500"
                        ></div>
                    </router-link>
                </li>
                <Button
                    @click="mobileMenu()"
                    text="Careers"
                    link="contact"
                    btnType="secondary"
                    class="w-5/6 mx-auto text-rivaudo-green border-rivaudo-green"
                />
            </ul>
        </div>
    </header>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
    components: {
        Button,
    },
    data() {
        return {
            mobileMenuOpen: false,
        };
    },
    methods: {
        mobileMenu() {
            const topBar = document.getElementById("top");
            const middleBar = document.getElementById("middle");
            const bottomBar = document.getElementById("bottom");

            if (this.mobileMenuOpen) {
                topBar.classList.remove(
                    "rotate-[-45deg]",
                    "relative",
                    "top-1.5"
                );
                middleBar.classList.remove("hidden");
                bottomBar.classList.remove("rotate-45", "relative", "bottom-1");
                document.body.style.overflow = "scroll";
                this.mobileMenuOpen = false;
            } else {
                topBar.classList.add("rotate-[-45deg]", "relative", "top-1.5");
                middleBar.classList.add("hidden");
                bottomBar.classList.add("rotate-45", "relative", "bottom-1");
                document.body.style.overflow = "hidden";
                this.mobileMenuOpen = true;
            }
        },
        closeMobileMenu() {
            if (this.mobileMenuOpen) {
                this.mobileMenu();
            }
        },
    },
    beforeMount() {
        window.addEventListener("resize", () => {
            if (window.innerWidth > 1024) {
                if (this.mobileMenuOpen) {
                    this.mobileMenu();
                }
            }
        });
    },
};
</script>

<style></style>
