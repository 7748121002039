<template>
    <home-banner></home-banner>
    <home-three-boxes></home-three-boxes>
    <home-all-business-needs></home-all-business-needs>
    <home-management></home-management>
    <home-smarter></home-smarter>
    <home-value-of-software></home-value-of-software>
    <home-mission-statement></home-mission-statement>
</template>

<script>
import HomeBanner from '@/components/home/HomeBanner.vue';
import HomeThreeBoxes from '@/components/home/HomeThreeBoxes.vue';
import HomeAllBusinessNeeds from '@/components/home/HomeAllBusinessNeeds.vue';
import HomeManagement from '@/components/home/HomeManagement.vue';
import HomeSmarter from '@/components/home/HomeSmarter.vue';
import HomeValueOfSoftware from '@/components/home/HomeValueOfSoftware.vue';
import HomeMissionStatement from '@/components/home/HomeMissionStatement.vue';



export default {
    components: {
        HomeBanner,
        HomeThreeBoxes,
        HomeAllBusinessNeeds,
        HomeManagement,
        HomeSmarter,
        HomeValueOfSoftware,
        HomeMissionStatement
    }
}
</script>

<style>

</style>