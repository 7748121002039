<template>
    <router-link
        v-if="btnType === 'primary'"
        :to="{ name: `${link}` }"
        class="px-10 pt-3 pb-2 text-xl font-bold text-center text-white uppercase rounded-3xl hover:bg-rivaudo-green-600"
        >{{ text }}</router-link
    >
    <router-link
        v-else-if="btnType === 'secondary'"
        :to="{ name: `${link}` }"
        class="px-10 pt-3 pb-2 text-xl font-bold text-center uppercase bg-white border rounded-3xl hover:opacity-40"
        >{{ text }}</router-link
    >
</template>

<script>
export default {
    props: {
        text: {
            type: String,
        },
        link: {
            type: String,
        },
        btnType: {
            type: String,
            default: "primary",
        },
    },
};
</script>

<style></style>
