<template>
    <div class="flex flex-col w-10/12 gap-6 mx-auto my-16">
        <div>
            <h3 class="text-4xl font-bold lg:text-5xl text-rivaudo-green">
                Available.
            </h3>
        </div>
        <div
            class="flex flex-col justify-between gap-6 lg:gap-20 lg:flex-row lg:text-lg"
        >
            <p>
                We offer 24/7 support and proactive monitoring to address any
                issues promptly and efficiently. Our dedicated team is always
                ready to assist, ensuring that any potential problems are
                resolved quickly to maintain seamless operation. We prioritize
                your need for reliable and consistent access to your software
                and services, making sure you are never left without the tools
                you need to succeed.
            </p>
            <p>
                We understand that availability is crucial for your business
                operations. Our solutions are designed with high availability in
                mind, ensuring that your systems are always up and running when
                you need them. We utilize robust infrastructure and redundant
                systems to minimize downtime and guarantee continuous operation.
            </p>
        </div>
        <div class="mx-auto my-0">
            <img src="../../assets/apple-watch-laying-on-iphone-white.jpg" />
        </div>
        <div
            class="my-10 text-2xl font-light text-center lg:text-6xl text-darkGray md:text-4xl"
        >
            <h4>
                Software should be
                <span class="font-bold text-rivaudo-green">simple.</span>
            </h4>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
