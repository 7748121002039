<template>
    <footer class="pt-16 pb-10 bg-white h-fit shadow-top lg:px-10">
        <router-link to="/">
            <div class="flex flex-col items-center gap-5 lg:items-start">
                <img src="../assets/rivaudo.svg" class="w-2/5 max-w-40" />
                <div class="h-0.5 bg-gray-200 w-3/4 lg:w-full"></div>
            </div>
        </router-link>
        <div class="mt-4">
            <ul
                class="flex flex-col items-center gap-8 text-center lg:flex-row"
            >
                <li>
                    <router-link
                        :to="{ name: 'home' }"
                        class="text-xl uppercase text-darkGray"
                        >Home</router-link
                    >
                </li>
                <li>
                    <router-link
                        :to="{ name: 'services' }"
                        class="text-xl uppercase text-darkGray"
                        >Services</router-link
                    >
                </li>
                <li>
                    <router-link
                        :to="{ name: 'resources' }"
                        class="text-xl uppercase text-darkGray"
                        >Resources</router-link
                    >
                </li>
                <li>
                    <router-link
                        :to="{ name: 'about' }"
                        class="text-xl uppercase text-darkGray"
                        >About us</router-link
                    >
                </li>
                <li>
                    <router-link
                        :to="{ name: 'blogs' }"
                        class="text-xl uppercase text-darkGray"
                        >Blog</router-link
                    >
                </li>
            </ul>
        </div>
        <div class="flex flex-col mt-16 text-center lg:items-center">
            <div class="flex justify-around mb-4 lg:w-1/2">
                <router-link
                    :to="{ name: 'about' }"
                    class="uppercase text-rivaudo-green"
                    >Privacy</router-link
                >
                <router-link
                    :to="{ name: 'about' }"
                    class="uppercase text-rivaudo-green"
                    >Terms & Conditions</router-link
                >
                <router-link
                    :to="{ name: 'about' }"
                    class="uppercase text-rivaudo-green"
                    >Support</router-link
                >
            </div>
            <small class="uppercase"
                >&copy; Copyright by <span class="font-bold">Rivaudo</span>. All
                rights reserved.</small
            >
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style></style>
