<template>
    <div class="bg-gray-100 py-0.5">
        <div
            class="flex flex-col w-10/12 gap-6 mx-auto my-16 lg:gap-36 lg:flex-row"
        >
            <div class="flex flex-col gap-6 lg:text-lg">
                <h3 class="text-4xl font-bold lg:text-5xl text-rivaudo-green">
                    Scalable.
                </h3>
                <p>
                    For us, scalability is a core principle of our software
                    solutions. We design our products to grow alongside your
                    business, ensuring seamless performance regardless of
                    demand. Our scalable architecture allows you to handle
                    increased workloads, user traffic, and data volume without
                    compromising efficiency or speed.
                </p>
                <p>
                    We understand that as your business evolves, your
                    technological needs change. Our commitment to scalability
                    ensures that your technology infrastructure remains robust
                    and capable of supporting your expanding operations. With
                    <span class="text-rivaudo-green">Rivaudo</span>, our
                    software will not only meet your current requirements but
                    also adapt to future challenges and opportunities, growing
                    in tandem with your business.
                </p>
            </div>
            <div class="flex flex-col gap-12 mt-8">
                <div class="flex gap-6">
                    <div>
                        <img
                            class="w-80 lg:w-60 rounded-xl"
                            src="../../assets/r-large.jpg"
                        />
                    </div>
                    <div>
                        <h3
                            class="mb-2 text-2xl font-bold uppercase text-rivaudo-green lg:text-3xl"
                        >
                            Rivaudo
                        </h3>
                        <p class="text-sm lg:text-base">
                            Our software excels in scalability, ensuring
                            seamless performance as your business grows. As your
                            business evolves, our adaptable architecture grows
                            with you, providing reliable and consistent
                            performance every step of the way.
                        </p>
                    </div>
                </div>
                <div class="flex gap-6">
                    <div>
                        <img
                            class="p-3 bg-white rounded-xl w-80 lg:w-60"
                            src="../../assets/competitors.jpg"
                        />
                    </div>
                    <div>
                        <h3 class="mb-2 text-2xl font-bold lg:text-3xl">
                            Competitors
                        </h3>
                        <p class="text-sm lg:text-base">
                            Many competitors struggle with scalability, often
                            resulting in performance bottlenecks and
                            inefficiencies as your business grows. Customization
                            options can be limited, making it difficult to
                            integrate new features or systems without
                            significant overhauls.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
