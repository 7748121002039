<template>
    <div>
        <div class="container mx-auto">
            <div class="px-8 py-4 mx-auto md:px-10 xl:w-2/3">
                <nav class="py-4">
                    <ul
                        class="flex items-center gap-4 text-base font-bold text-darkGray"
                    >
                        <li>
                            <router-link
                                to="/"
                                class="hover:text-rivaudo-green"
                            >
                                <svg
                                    class="hover:text-rivaudo-green sm:w-3/4"
                                    width="30"
                                    height="27"
                                    viewBox="0 0 30 27"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M29.9921 13.3107C29.9921 14.2485 29.2107 14.983 28.325 14.983H26.6579L26.6944 23.3289C26.6944 23.4696 26.684 23.6103 26.6684 23.7509V24.5949C26.6684 25.7462 25.7358 26.6788 24.5845 26.6788H23.7509C23.6936 26.6788 23.6363 26.6788 23.579 26.6736C23.5061 26.6788 23.4331 26.6788 23.3602 26.6788L21.6671 26.6736H20.4167C19.2654 26.6736 18.3329 25.741 18.3329 24.5897V23.3394V20.0052C18.3329 19.0831 17.5879 18.3381 16.6658 18.3381H13.3316C12.4095 18.3381 11.6645 19.0831 11.6645 20.0052V23.3394V24.5897C11.6645 25.741 10.7319 26.6736 9.5806 26.6736H8.33028H6.66839C6.59025 26.6736 6.5121 26.6684 6.43396 26.6631C6.37144 26.6684 6.30892 26.6736 6.24641 26.6736H5.41286C4.26152 26.6736 3.32899 25.741 3.32899 24.5897V18.7549C3.32899 18.708 3.32899 18.6559 3.3342 18.609V14.9778H1.6671C0.729355 14.9778 0 14.2485 0 13.3055C0 12.8367 0.15629 12.4199 0.520968 12.0552L13.8734 0.416774C14.2381 0.0520968 14.6548 0 15.0195 0C15.3842 0 15.801 0.104194 16.1135 0.364678L29.4191 12.0604C29.8358 12.4251 30.0442 12.8419 29.9921 13.3107Z"
                                    />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <img src="@/assets/icons/chevron-right.svg" />
                        </li>
                        <li>
                            <router-link
                                class="hover:text-rivaudo-green"
                                :to="{ name: 'blogs' }"
                            >
                                Blogs
                            </router-link>
                        </li>
                        <li>
                            <img src="@/assets/icons/chevron-right.svg" />
                        </li>
                        <li>{{ title }}</li>
                    </ul>
                </nav>
                <div class="">
                    <h1 class="my-4 text-3xl font-extrabold text-black">
                        {{ title }}
                    </h1>
                    <hr />
                    <p class="my-4 italic font-medium text-darkGray">
                        Last Updated on {{ date }}
                    </p>
                    <p class="mb-4 font-light">
                        by
                        <router-link
                            to="#author"
                            class="font-bold text-rivaudo-green hover:text-rivaudo-green-600"
                        >
                            Salvatore Ribaudo, CEO - Rivaudo
                        </router-link>
                    </p>
                    <div v-html="blogContent"></div>
                    <div
                        class="flex h-40 my-20 bg-white rounded-3xl"
                        style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
                    >
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import blogs from "@/blogs/blogsList.json";

export default {
    data() {
        return {
            slug: this.$route.params.slug,
            title: "",
            date: "",
            blogContent: "",
        };
    },
    created() {
        this.loadBlogContent();
        this.getInfo();
    },
    methods: {
        async loadBlogContent() {
            try {
                const htmlContent = await import(`@/blogs/${this.slug}.html`);
                this.blogContent = htmlContent.default;
            } catch (error) {
                console.log("Blog not found", error);
                this.$router.push({ name: "notfound" });
            }
        },

        formatDate(date) {
            if (date) {
                let formattedDate = new Date(date);
                return formattedDate.toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            } else {
                return false;
            }
        },

        getInfo() {
            let allBlogs = [];
            for (let category in blogs) {
                allBlogs = [...allBlogs, ...blogs[category]];
            }
            for (let blog of allBlogs) {
                if (blog.slug === this.slug) {
                    this.title = blog.title;
                    this.date =
                        this.formatDate(blog.date) || "September 8, 2023";
                    break;
                }
            }
        },
    },
};
</script>
