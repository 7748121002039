<template>
    <div
        class="flex flex-col-reverse items-center justify-around lg:py-12 lg:px-20 lg:flex-row"
    >
        <div
            class="flex flex-col items-center text-center lg:text-left lg:items-start"
        >
            <h1
                class="mt-10 text-4xl font-light text-darkGray lg:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl mb-7"
            >
                Explore
                <span class="font-bold text-rivaudo-green">everything</span>
                <br />
                we have to say...
            </h1>
            <h2
                class="px-4 text-sm font-normal lg:px-0 md:w-4/5 lg:w-3/4 xl:w-4/5 md:text-lg text-darkGray 2xl:w-4/6"
            >
                Delve into our extensive library of resources, including blogs,
                news updates, and FAQs, designed to keep you ahead in the world
                of software.
            </h2>
        </div>
        <div class="w-3/4 lg:w-1/2 lg:flex lg:justify-end">
            <img
                class="mx-auto"
                width="540"
                height="450"
                src="@/assets/banners/paper-notebook.webp"
            />
        </div>
    </div>
    <div class="my-10">
        <img class="w-full" src="@/assets/banner-bottom.png" />
    </div>
</template>

<script>
export default {};
</script>

<style></style>
