<template>
    <div
        class="flex flex-col w-11/12 py-10 mx-auto text-center md:w-3/4 lg:w-1/2"
    >
        <h3 class="text-4xl font-bold capitalize md:text-5xl text-darkGray">
            Our mission:
        </h3>
        <p class="mt-10 mb-6 text-darkGray">
            At Rivaudo, we recognize that enterprise software is the backbone of
            every successful business. By empowering businesses with innovative,
            reliable, and intuitive software solutions, we enable them to
            operate more efficiently and achieve their goals. We believe that
            when businesses thrive, they drive positive change, foster economic
            growth, and improve the quality of life in our communities.
        </p>
        <p class="text-3xl text-rivaudo-green">Software made better.</p>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
