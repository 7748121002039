<template>
    <Navbar></Navbar>
    <div class="mt-28 lg:mt-20">
        <router-view />
    </div>
    <Footer></Footer>
</template>

<script>
import Navbar from "@/components/NavigationBar.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Navbar,
        Footer,
    },
};
</script>

<style></style>
