<template>
    <div
        class="container flex flex-col items-center justify-around gap-12 mx-auto my-20 lg:flex-row"
    >
        <div
            style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
            class="relative w-10/12 p-6 bg-white lg:bottom-0 lg:w-1/3 h-fit rounded-xl"
        >
            <div class="mb-8">
                <img class="w-12" src="../../assets/icons/pencil.jpg" />
            </div>
            <div>
                <h2 class="text-2xl font-bold">Our Blogs</h2>
            </div>
            <hr class="block w-1/4 my-4 border-b-2 border-b-rivaudo-green" />
            <div>
                <p class="text-sm text-darkGray">
                    Empower your digital commerce strategy with our advanced
                    ecommerce solutions. We offer intuitive UX/UI design, PCI
                    DSS-compliant payment processing, and personalized customer
                    journey optimization to drive online sales and enhance
                    customer satisfaction.
                </p>
            </div>
            <div class="mt-6">
                <a
                    class="flex items-center gap-4 px-3 py-1 font-extrabold uppercase border cursor-pointer border-darkGray text-darkGray rounded-3xl w-fit hover:opacity-40"
                    @click="moveTo('blogs')"
                >
                    <span class="mt-0.5">Explore Blogs</span>
                    <img src="../../assets/icons/arrow-right.svg" />
                </a>
            </div>
        </div>
        <div
            style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
            class="relative w-10/12 p-6 bg-white lg:bottom-0 lg:w-1/3 h-fit rounded-xl"
        >
            <div class="mb-6">
                <img class="w-14" src="../../assets/icons/newspaper.jpg" />
            </div>
            <div>
                <h2 class="text-2xl font-bold">News & Updates</h2>
            </div>
            <hr class="block w-1/4 my-4 border-b-2 border-b-rivaudo-green" />
            <div>
                <p class="text-sm text-darkGray">
                    Empower your digital commerce strategy with our advanced
                    ecommerce solutions. We offer intuitive UX/UI design, PCI
                    DSS-compliant payment processing, and personalized customer
                    journey optimization to drive online sales and enhance
                    customer satisfaction.
                </p>
            </div>
            <div class="mt-6">
                <a
                    class="flex items-center gap-4 px-3 py-1 font-extrabold uppercase border cursor-pointer border-darkGray text-darkGray rounded-3xl w-fit hover:opacity-40"
                    @click="moveTo('news')"
                >
                    <span class="mt-0.5">Explore News</span>
                    <img src="../../assets/icons/arrow-right.svg" />
                </a>
            </div>
        </div>
        <div
            style="box-shadow: 0px 5px 11px 5px rgba(0, 0, 0, 0.25)"
            class="relative w-10/12 p-6 bg-white lg:bottom-0 lg:w-1/3 h-fit rounded-xl"
        >
            <div class="mb-6">
                <img class="w-10" src="../../assets/icons/question-mark.jpg" />
            </div>
            <div>
                <h2 class="text-2xl font-bold">FAQ</h2>
            </div>
            <hr class="block w-1/4 my-4 border-b-2 border-b-rivaudo-green" />
            <div>
                <p class="text-sm text-darkGray">
                    Empower your digital commerce strategy with our advanced
                    ecommerce solutions. We offer intuitive UX/UI design, PCI
                    DSS-compliant payment processing, and personalized customer
                    journey optimization to drive online sales and enhance
                    customer satisfaction.
                </p>
            </div>
            <div class="mt-6">
                <a
                    class="flex items-center gap-4 px-3 py-1 font-extrabold uppercase border cursor-pointer border-darkGray text-darkGray rounded-3xl w-fit hover:opacity-40"
                    @click="moveTo('faq')"
                >
                    <span class="mt-0.5">Explore FAQ</span>
                    <img src="../../assets/icons/arrow-right.svg" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        moveTo(val) {
            document.getElementById(val).scrollIntoView();
        },
    },
};
</script>

<style></style>
