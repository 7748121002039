<template>
    <ResourcesBanner></ResourcesBanner>
    <ResourcesBoxes></ResourcesBoxes>
    <ResourcesFeaturedBlog></ResourcesFeaturedBlog>
    <ResourcesNews></ResourcesNews>
    <ResourcesFAQ></ResourcesFAQ>
</template>

<script>
import ResourcesBanner from "@/components/resources/ResourcesBanner.vue";
import ResourcesBoxes from "@/components/resources/ResourcesBoxes.vue";
import ResourcesFeaturedBlog from "@/components/resources/ResourcesFeaturedBlog.vue";
import ResourcesNews from "@/components/resources/ResourcesNews.vue";
import ResourcesFAQ from "@/components/resources/ResourcesFAQ.vue";
// import blogTitles from "../../public/blog-list/blogs.json";

export default {
    components: {
        ResourcesBanner,
        ResourcesBoxes,
        ResourcesFeaturedBlog,
        ResourcesNews,
        ResourcesFAQ
    },
    data() {
        return {
            // blogs: blogTitles
        };
    },
};
</script>

<style></style>
