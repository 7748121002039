<template>
    <div>
        <div>
            <h2 class="text-5xl text-center text-darkGray">
                See what we have to say...
            </h2>
        </div>
        <div class="container w-10/12 mx-auto">
            <div
                v-for="(blog, i) in featuredBlogs"
                :key="i"
                class="my-4 shadow-lg"
            >
                <div>
                    <img src="" />
                </div>
                {{ blog.title }}
            </div>
        </div>
    </div>
</template>

<script>
import blogs from "@/blogs/blogsList.json";

export default {
    data() {
        return {
            blogs: blogs,
            featuredBlogs: [],
        };
    },
    methods: {
        addFeaturedBlogs() {
            for (let blog in blogs) {
                if (this.featuredBlogs.length < 4) {
                    this.featuredBlogs.push(blogs[blog][0]);
                }
            }
        },
    },
    beforeMount() {
        this.addFeaturedBlogs();
    },
};
</script>

<style></style>
