<template>
    <div class="w-10/12 mx-auto my-24">
        <div class="mt-10 text-2xl md:text-5xl sm:text-4xl">
            <h3 class="mb-1 md:mb-4 text-darkGray">
                <span class="font-light">
                    Hi, I'm <span class="uppercase">Rivaudo</span>.
                </span>
            </h3>
            <h3 class="font-bold text-rivaudo-green">
                Software made smarter...
            </h3>
        </div>
        <div
            class="flex flex-col gap-8 my-8 text-darkGray lg:gap-36 lg:flex-row"
        >
            <div class="lg:w-1/2">
                <p>
                    We engineer our software to be exceptionally smart,
                    integrating meticulous design and advanced engineering
                    practices to embed intelligence throughout our solutions. By
                    leveraging cutting-edge technologies such as artificial
                    intelligence and machine learning, our software not only
                    anticipates challenges but also adapts swiftly, providing
                    your business with unparalleled efficiency, foresight, and
                    the capability to thrive in dynamic environments.
                </p>
            </div>
            <div class="lg:w-1/2">
                <p>
                    Our commitment to smarter software extends beyond
                    functionality; it encompasses our dedication to customer
                    success. We work closely with our clients to understand
                    their unique needs and deliver customized solutions that
                    integrate seamlessly into their workflows. With
                    <span class="text-rivaudo-green">Rivaudo</span>, you're not
                    just getting software; you're gaining a strategic partner
                    invested in your long-term success. Together, we can
                    transform the way you do business and unlock new
                    opportunities for innovation and excellence.
                </p>
            </div>
        </div>
        <router-link
            class="px-4 pt-3 pb-2.5 font-bold text-white uppercase rounded-full bg-rivaudo-green hover:bg-rivaudo-green-600"
            :to="{ name: 'about' }"
            >Learn More<img
                class="inline-block mb-0.5 ml-4"
                src="../../assets/icons/white-arrow-right.svg"
        /></router-link>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
