<template>
    <div>
        <div>
            <h2
                class="mt-10 text-3xl text-center lg:text-7xl text-darkGray mb-7"
            >
                <span class="font-light"> Custom software for </span>
                <br />
                <span class="font-semibold text-rivaudo-green">
                    all your business needs.
                </span>
            </h2>
        </div>
        <div
            class="grid w-10/12 grid-cols-2 grid-rows-3 mx-auto mt-12 mb-24 lg:mt-24 lg:w-11/12 lg:grid-cols-3 lg:grid-rows-2 gap-y-10 lg:gap-y-20 gap-x-10 lg:gap-x-0"
        >
            <div class="text-center w-fit justify-self-center">
                <div class="flex justify-center">
                    <img
                        class="w-24 lg:w-28"
                        src="../../assets/icons/scale-up.jpg"
                    />
                </div>
                <div class="mt-4 mb-2 text-xl font-bold">Scalable</div>
                <div class="mx-auto my-0 lg:w-1/2">
                    <p class="text-sm lg:text-md text-darkGray">
                        Easily expand and adapt with our scalable software,
                        designed to grow with your business.
                    </p>
                </div>
            </div>
            <div class="text-center w-fit justify-self-center">
                <div class="flex justify-center">
                    <img
                        class="w-24 lg:w-28"
                        src="../../assets/icons/robust-lock.jpg"
                    />
                </div>
                <div class="mt-4 mb-2 text-xl font-bold">Robust</div>
                <div class="mx-auto my-0 lg:w-1/2">
                    <p class="text-sm lg:text-md text-darkGray">
                        Software solutions that are meticulously crafted for
                        robust reliability and performance.
                    </p>
                </div>
            </div>
            <div class="text-center w-fit justify-self-center">
                <div class="flex justify-center">
                    <img
                        class="w-24 lg:w-28"
                        src="../../assets/icons/building-blocks-customizable.jpg"
                    />
                </div>
                <div class="mt-4 mb-2 text-xl font-bold">Customizable</div>
                <div class="mx-auto my-0 lg:w-1/2">
                    <p class="text-sm lg:text-md text-darkGray">
                        Tailored software to fit your exact needs with
                        customizable features and configurations.
                    </p>
                </div>
            </div>
            <div class="text-center w-fit justify-self-center">
                <div class="flex justify-center">
                    <img
                        class="w-24 lg:w-28"
                        src="../../assets/icons/speedometer.jpg"
                    />
                </div>
                <div class="mt-4 mb-2 text-xl font-bold">Optimized</div>
                <div class="mx-auto my-0 lg:w-1/2">
                    <p class="text-sm lg:text-md text-darkGray">
                        Achieve unmatched performance with our optimized task
                        handling and threading.
                    </p>
                </div>
            </div>
            <div class="text-center w-fit justify-self-center">
                <div class="flex justify-center">
                    <img
                        class="w-24 lg:w-28"
                        src="../../assets/icons/integrated.jpg"
                    />
                </div>
                <div class="mt-4 mb-2 text-xl font-bold">Integrated</div>
                <div class="mx-auto my-0 lg:w-1/2">
                    <p class="text-sm lg:text-md text-darkGray">
                        Seamlessly integrate with our API, combining multiple
                        components for optimal processing.
                    </p>
                </div>
            </div>
            <div class="text-center w-fit justify-self-center">
                <div class="flex justify-center">
                    <img
                        class="w-24 lg:w-28"
                        src="../../assets/icons/automated-flow.jpg"
                    />
                </div>
                <div class="mt-4 mb-2 text-xl font-bold">Automated</div>
                <div class="mx-auto my-0 lg:w-1/2">
                    <p class="text-sm lg:text-md text-darkGray">
                        Increase productivity with automated tasks and
                        workflows, freeing up time for strategic initiatives.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
