<template>
    <div
        class="relative mb-[860px] lg:mb-56 md:mb-[600px] md:mt-56 xl:mb-80 2xl:mb-56"
    >
        <div
            class="flex flex-col-reverse mx-auto my-0 xl:flex-row lg:w-10/12 gap-y-44"
        >
            <div
                class="flex justify-center mx-auto my-0 lg:justify-start lg:w-3/4"
            >
                <img
                    @click="phoneShiftUp"
                    class="relative bottom-0 transition-all cursor-pointer"
                    src="../../assets/mobile-view.png"
                    alt="Mobile view of Rivaudo software dashboard"
                />
            </div>
            <div class="mx-auto my-0 lg:w-3/4">
                <h2
                    class="mt-10 text-3xl text-center lg:text-5xl text-darkGray mb-7"
                >
                    <span class="font-light"> Management is now </span>
                    <br />
                    <span class="font-semibold text-rivaudo-green">
                        faster & easier.
                    </span>
                </h2>
                <div
                    class="flex flex-col lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-0 gap-y-8"
                >
                    <div
                        class="flex items-center w-10/12 p-4 mx-auto my-0 bg-white border rounded-sm lg:w-full border-lightgray"
                    >
                        <div class="w-1/4">
                            <img
                                class="w-3/4"
                                src="../../assets/icons/pie-chart.jpg"
                            />
                        </div>
                        <div class="w-3/4">
                            <h3 class="font-bold">Analytics</h3>
                            <p class="text-xs">
                                Harness actionable insights through advanced
                                analytics to drive informed decisions.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex items-center w-10/12 p-4 mx-auto my-0 bg-white border rounded-sm lg:w-full border-lightgray"
                    >
                        <div class="w-1/4">
                            <img
                                class="w-3/4"
                                src="../../assets/icons/performance.jpg"
                            />
                        </div>
                        <div class="w-3/4">
                            <h3 class="font-bold">Performance</h3>
                            <p class="text-xs">
                                Optimize operational efficiency and maximize
                                productivity with our performance-focused
                                solutions.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex items-center w-10/12 p-4 mx-auto my-0 bg-white border rounded-sm lg:w-full border-lightgray"
                    >
                        <div class="w-1/4">
                            <img
                                class="w-3/4"
                                src="../../assets/icons/eye.jpg"
                            />
                        </div>
                        <div class="w-3/4">
                            <h3 class="font-bold">Monitoring</h3>
                            <p class="text-xs">
                                Ensure constant vigilance over your systems with
                                proactive monitoring and real-time alerts.
                            </p>
                        </div>
                    </div>
                    <div
                        class="flex items-center w-10/12 p-4 mx-auto my-0 bg-white border rounded-sm lg:w-full border-lightgray"
                    >
                        <div class="w-1/4">
                            <img
                                class="w-3/4"
                                src="../../assets/icons/security.jpg"
                            />
                        </div>
                        <div class="w-3/4">
                            <h3 class="font-bold">Security</h3>
                            <p class="text-xs">
                                Protect your assets and data with robust
                                security measures tailored to your business
                                needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="absolute w-full py-10 lg:py-24 top-3/4 lg:top-2/3 h-fit bg-gradient-to-r from-secondary-500 to-rivaudo-green xl:top-1/2"
        >
            <div class="">
                <h2
                    class="mb-10 text-3xl text-center lg:text-5xl text-darkGray"
                >
                    <span class="font-light"> Great features </span>
                    <br />
                    <span class="font-semibold text-white">
                        at your fingertips...
                    </span>
                </h2>
            </div>
            <div
                class="flex flex-col items-center px-4 mx-auto my-0 lg:gap-x-20 lg:flex-row gap-y-10 lg:w-10/12"
            >
                <div class="flex flex-col items-center text-center">
                    <div class="w-full h-40">
                        <img
                            src="../../assets/icons/graph.svg"
                            class="w-40 mx-auto"
                        />
                    </div>
                    <h3 class="my-3 text-xl font-bold">
                        Efficient Asset Tracking
                    </h3>
                    <p class="font-bold text-darkGray">
                        Streamline asset management and enhance operational
                        efficiency with our advanced tracking solutions.
                    </p>
                </div>
                <div class="flex flex-col items-center text-center">
                    <div class="w-full h-40">
                        <img
                            src="../../assets/icons/cybersecurity.svg"
                            class="w-40 mx-auto"
                        />
                    </div>
                    <h3 class="my-3 text-xl font-bold">
                        Advanced Cybersecurity
                    </h3>
                    <p class="font-bold text-darkGray">
                        Implement cutting-edge cybersecurity measures to
                        safeguard your business against evolving threats.
                    </p>
                </div>
                <div class="flex flex-col items-center text-center">
                    <div class="w-full h-40">
                        <img
                            src="../../assets/icons/bug.svg"
                            class="w-40 mx-auto"
                        />
                    </div>
                    <h3 class="my-3 text-xl font-bold">Live Bug Reporting</h3>
                    <p class="font-bold text-darkGray">
                        Enable real-time bug reporting and resolution for easier
                        software maintenance and enhancement.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            phoneUp: false,
        };
    },
    methods: {
        phoneShiftUp(e) {
            if (this.phoneUp) {
                e.target.style.bottom = "0";
                this.phoneUp = false;
            } else {
                e.target.style.bottom = "10rem";
                this.phoneUp = true;
            }
        },
    },
};
</script>

<style></style>
