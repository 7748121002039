<template>
    <div class="flex flex-col w-10/12 mx-auto my-16 gap-y-6">
        <div>
            <h3 class="text-4xl font-bold lg:text-5xl text-rivaudo-green">
                Secure.
            </h3>
        </div>
        <div class="flex flex-col gap-6 lg:text-lg">
            <p>
                At <span class="text-rivaudo-green">Rivaudo</span>, security is
                at the core of everything we do. We protect your data with
                state-of-the-art encryption, multi-factor authentication, and
                continuous monitoring. Additionally, we stay ahead of the curve
                by keeping abreast of the latest security trends and
                technologies, ensuring that our solutions are always up-to-date
                and resilient against emerging threats.
            </p>
            <p>
                We believe that secure software is the foundation of trust
                between a company and its clients. We prioritize security from
                the initial design phase through to deployment and maintenance.
                Our comprehensive approach includes regular security audits,
                vulnerability assessments, and penetration testing to identify
                and mitigate risks before they become issues.
            </p>
        </div>
        <div
            class="flex flex-col gap-16 mt-8 lg:items-center lg:flex-row lg:mt-0"
        >
            <h3 class="text-3xl 2xl:text-4xl lg:w-1/3">
                Data breaches cost
                <br />
                businesses on avg.
                <span
                    ref="targetElement"
                    class="block mt-5 text-5xl font-extrabold"
                    >${{ incidentAmount.toFixed(1) }} million</span
                >
                per incident.
            </h3>

            <div class="lg:w-1/3">
                <canvas class="mx-auto my-0" width="220" id="myChart"></canvas>
                <div>
                    <p class="my-8 text-lg font-medium text-center lg:text-2xl">
                        of software applications have experienced a security
                        breach in the last year.
                    </p>
                </div>
            </div>

            <div class="lg:w-1/3">
                <h3 class="text-2xl text-center xs:text-3xl 2xl:text-4xl">
                    <span class="block mb-4 text-6xl font-bold"
                        >{{ affectedByCrime }}%</span
                    >
                    of businesses will be affected by cybercrime
                    <span class="font-extrabold">before 2030</span>.
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
import Chart, { layouts } from "chart.js/auto";

export default {
    data() {
        return {
            incidentAmount: 0,
            doughnutNumber: 85,
            affectedByCrime: 0,
        };
    },
    mounted() {
        this.createObserver();
    },
    methods: {
        createObserver() {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: 0,
            };

            const observer = new IntersectionObserver(
                this.handleIntersect,
                options
            );
            observer.observe(this.$refs.targetElement);
        },
        handleIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.onElementVisible();
                    observer.unobserve(entry.target);
                }
            });
        },
        onElementVisible() {
            const incidentCost = setInterval(() => {
                if (this.incidentAmount < 4.5) {
                    this.incidentAmount += 0.1;
                } else {
                    clearInterval(incidentCost);
                }
            }, 50);

            const cyberCrime = setInterval(() => {
                if (this.affectedByCrime < 90) {
                    this.affectedByCrime += 1;
                } else {
                    clearInterval(cyberCrime);
                }
            }, 20);

            // Doughnut Chart

            const ctx = document.getElementById("myChart");

            const options = {
                plugins: {
                    legend: true,
                    tooltip: {
                        enabled: false,
                    },
                    textInside: {
                        text: this.doughnutNumber + "%",
                        color: "#000000",
                        fontSize: 50,
                    },
                },
            };

            Chart.register({
                id: "textInside",
                afterDatasetsDraw: function (chart, _) {
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height;
                    const fontSize = options.plugins.textInside.fontSize;
                    ctx.font = fontSize + "px Comfortaa";
                    ctx.fillStyle = options.plugins.textInside.color;
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    const text = options.plugins.textInside.text;
                    const textX = Math.round(width / 2);
                    const textY = Math.round(height / 1.85);
                    ctx.fillText(text, textX, textY);
                },
            });

            const myChart = new Chart(ctx, {
                type: "doughnut",
                data: {
                    datasets: [
                        {
                            data: [15, 85],
                            backgroundColor: ["#F5F5F5", "#53BA00"],

                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    animation: {
                        duration: 2000,
                    },
                    title: {
                        display: true,
                    },
                    responsive: false,
                    cutout: "90%",
                    tooltip: false,
                    events: [],
                    plugins: {
                        tooltip: {
                            enabled: false,
                        },
                    },
                    scales: {},
                },
            });

            // End Doughnut Chart
        },
    },
};
</script>

<style></style>
