<template>
    <div
        class="flex flex-col-reverse items-center justify-between lg:py-12 lg:px-20 lg:flex-row"
    >
        <div
            class="flex flex-col items-center text-center lg:text-left lg:items-start"
        >
            <h1
                class="mt-10 text-4xl font-light text-darkGray lg:text-5xl md:text-5xl xl:text-6xl 2xl:text-7xl mb-7"
            >
                Boost your efficiency,
                <br />
                with
                <span class="font-bold text-rivaudo-green"
                    >custom software.</span
                >
            </h1>
            <h2
                class="px-4 text-sm font-normal lg:px-0 md:w-4/5 lg:w-3/4 xl:w-4/5 md:text-lg text-darkGray 2xl:w-4/6"
            >
                Spend less time on managing your business and more time on the
                things that truly matter.
            </h2>
        </div>
        <div class="flex justify-center w-3/4 lg:w-1/3">
            <img
                loading="eager"
                width="488"
                height="440"
                src="@/assets/banners/macbook-air.webp"
            />
        </div>
    </div>
    <div class="my-10">
        <img class="w-full" src="@/assets/banner-bottom.png" />
    </div>
</template>

<script>
export default {};
</script>

<style></style>
